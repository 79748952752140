import React, { useState } from "react";
import './quote.css'
import axios from 'axios';
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import beforePic1 from './IMG_9227.jpeg'
import afterPic1 from './IMG_9812.jpeg'
import beforePic2 from './before-junk2.jpg'
import afterPic2 from './junk-after2.jpg'
import beforePic3 from './Before Recent Junk Removal Pic.jpeg'
import afterPic3 from './After Recent Junk Removal pic.jpeg'
import toast,{Toaster} from 'react-hot-toast'


const Quote = () => {
  const [error , setError] =useState('`')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    date: '',
    time: '',
    files: [],
    id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  
    // Reset error message when the input changes
    if (error) {
      setError('');
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      files: e.target.files, // Store the selected files
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = new FormData();
    const selectedDate = new Date(formData.date)
    const today = new Date()
    today.setHours(0,0,0,0);
    if(selectedDate < today){
      setError('The selected date cannot be in the past')
    }else{
      setError('')
    }
    // Append form fields
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('address', formData.address);
    data.append('date', formData.date);
    data.append('time', formData.time);
    data.append('id', formData.id)

    // Append each file to the FormData object
    for (let i = 0; i < formData.files.length; i++) {
      data.append('files', formData.files[i]);
    }

    try {
      const response = await axios.post('https://wedumpjunk-backend.vercel.app/quotes/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('Files uploaded successfully');
        data.append('quoteId', response.data.quoteId)
        console.log(response.data)
      } else {
        alert('File upload failed');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Error uploading files');
    }
    finally{
    setIsSubmitting(false);
    }
  };
  const LoadingSpinner = () => (
    <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
);


  return (

    <div className="quote-container ">
      <Nav />
      <Toaster/>
  
        <h1 className="HEADER">Get Your Quote</h1>
        <h3 className="quote-sub-header">Or can send photo to 813-459-5731</h3>
   
      <div className="form-container">
        <div className="grid-container">
          <form className="quote-form" onSubmit={handleSubmit}>
            {/* First Column */}
            <div className="form-group">
              <label className="quote-label" htmlFor="name">Name</label>
              <input className="quote-input" type="text" id="name" name="name" onChange={handleChange} value={formData.name} required />
            </div>
            <div className="form-group">
              <label className="quote-label" htmlFor="email">Email</label>
              <input className="quote-input" type="email" id="email" name="email" onChange={handleChange} value={formData.email} required />
            </div>
            <div className="form-group">
              <label className="quote-label" htmlFor="date">Date</label>
              <input className="quote-input" type="date" id="date" name="date" onChange={handleChange} value={formData.date} required />
              {error && <p className="error">{error}</p>}
            </div>

            {/* Second Column */}
            <div className="form-group">
              <label className="quote-label" htmlFor="time">Time</label>
              <input className="quote-input" type="time" id="time" name="time" min="09:00" max="17:00" onChange={handleChange} value={formData.time} required />
            </div>
            <div className="form-group">
              <label className="quote-label" htmlFor="address">Address</label>
              <input className="quote-input" type="text" id="address" name="address" onChange={handleChange} value={formData.address} required />
            </div>
            <div className="form-group">
              <label className="quote-label" htmlFor="files">Upload Photos</label>
              <input className="quote-input" type="file" id="files" name="files" multiple onChange={handleFileChange} required />
            </div>
            <button className="green-btn" type="submit">
            {isSubmitting ? (
                    <span>Submitting... <LoadingSpinner /></span> // Show loading animation
                ) : (
                    'Send Quote'
                )}
            </button>
            
          </form>
        </div>
      </div>
      <div className="container-service">
        <div className="service-header-1">
          <h1 className="service-header">Services</h1>
          <p className="service-sub-header">
          To the right, you’ll find examples of common issues we frequently encounter and expertly resolve.</p>
        </div>

        <div className="service-image-wrapper-1">
          <img src={beforePic1} alt="before-photo" className="service-wrapped-img" />
        </div>
        <div className="service-image-wrapper-2">
          <img src={beforePic2} alt="before-photo" className="service-wrapped-img" />
        </div>
        <div className="service-image-wrapper-3">
          <img src={beforePic3} alt="before-photo" className="service-wrapped-img" />
        </div>
      </div>
      <div className="container-service-2">
        <div className="service-header-2">
          <h1 className="service-header">Results</h1>
          <p className="service-sub-header">Here you can see the impressive transformations we deliver. We consistently exceed expectations and take pride in the results we achieve</p>
        </div>

        <div className="service-image-wrapper-after-1">
          <img src={afterPic1} alt="after-photo" className="service-wrapped-img" />
        </div>
        <div className="service-image-wrapper-after-2">
          <img src={afterPic2} alt="after-photo" className="service-wrapped-img" />
        </div>
        <div className="service-image-wrapper-after-3">
          <img src={afterPic3} alt="after-photo" className="service-wrapped-img" />
        </div>
      </div>
      <Footer />
    </div>

  );
  
};


export default Quote;