import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import toast,{Toaster} from 'react-hot-toast'
import './pay.css'
const stripePromise = loadStripe('pk_live_51Q5LIUIhrQM6788hR5Y0kaEgVGtYCyESELB5Wr7cQFFiPVaPhG57V7Rkv42o73NRtfbwHw0duB1JX0b5nxEYkWTA00WbQJbbhp');

const Pay = () => {
    const [quoteId, setQuoteId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [isAuthorized, setIsAuthorized] = useState(false);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get('source');

        if (source === 'email') {
            setIsAuthorized(true); // User is authorized
        } else {
            setIsAuthorized(false); // User is not authorized
            navigate('/') // Redirect to home if not authorized
        }
    }, [navigate]);

    // Effect to fetch the quote only if the user is authorized
    useEffect(() => {
        if (isAuthorized) {
            const fetchQuote = async () => {
                try {
                    const response = await axios.get(`https://wedumpjunk-backend.vercel.app/quotes/${id}`);
                    setQuoteId(response.data); // Set the fetched quote data
                } catch (error) {
                    console.error("Error fetching the quote:", error);
                }
                
            };
            fetchQuote(); // Call the fetchQuote function
        }
    }, [id, isAuthorized]);


    const handleSubmit = async (event) => {
        event.preventDefault(); 
        setIsSubmitting(true);
        const amountInDollars = quoteId.amount;
        console.log(amountInDollars)
        // Ensure that stripe and elements are loaded
        if (!stripe || !elements) return;
        
    
        // Create a payment intent on your backend
        const response = await fetch('https://wedumpjunk-backend.vercel.app/pays/create-checkout-session', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ amount: amountInDollars  }) // Use dynamic amount
        });
        console.log(amountInDollars)
    
        if (!response.ok) {
            console.error("Error creating payment intent:", response);
            alert("Failed to create payment session.");
            return;
        }
    
        const { clientSecret } = await response.json();
    
        // Confirm the card payment
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });
    
        if (error) {
            toast.error("Payment failed: " + error.message);
        } else if (paymentIntent.status === 'succeeded') {
            toast.success('Files uploaded successfully'+ Math.round((paymentIntent.amount/100) + ' USD'))
        
    
            // Send payment details to your backend
            await axios.post('https://wedumpjunk-backend.vercel.app/pays/payment', {
                amount: paymentIntent.amount, // Amount in pennies
                quoteId: quoteId.id, // Assuming quoteId contains the ID of the quote
                clientEmail: quoteId.email, // Email to send thank you
                clientName: quoteId.name, // Name to personalize the email
                paymentIntentId: paymentIntent.id // Stripe Payment Intent ID
            });
            setIsSubmitting(false);
        } 
        
    };
    
    const LoadingSpinner = () => (
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );

    if (!quoteId) {
        return <div>Loading...</div>; // Show a loading message or spinner while fetching
    }

    return (
        <div className='payment-container'>
        <h1 className='payment-title'>Pay Page</h1>
        <div className='payment-info'>
            <p className='payment-detail'>Name: {quoteId.name}</p>
            <p className='payment-detail'>Email: {quoteId.email}</p>
            <p className='payment-detail'>Address: {quoteId.address}</p>
            <p className='payment-detail'>Date: {quoteId.date}</p>
            <p className='payment-detail'>Time: {quoteId.time}</p>
            <p className='payment-amount'>Amount: {quoteId.amount} USD</p> {/* Display amount in dollars */}
        </div>
        <form id="payment-form" className='payment-form' onSubmit={handleSubmit}>
            <CardElement className='card-element' />
            <button type="submit" className='pay-button' disabled={!stripe}>
            {isSubmitting ? (
                    <span>Submitting... <LoadingSpinner /></span> // Show loading animation
                ) : (
                    'Pay'
                )}</button>
        </form>
    </div>
    );
};

const PayWrapper = () => (
    <Elements stripe={stripePromise}>
        <Toaster/>
        <Pay />
    </Elements>
);

export default PayWrapper;
