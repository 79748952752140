import React, { useState } from "react";
import './contact.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from '../components/Nav';
import Grif from './about-gif.jpg'
import Footer from '../components/Footer';
import axios from 'axios';
import toast,{Toaster} from 'react-hot-toast'
const Contact = () => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    phone: '',
    message: '',
    name: '',
    email: ''
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post('https://wedumpjunk-backend.vercel.app/users', formData);
      console.log(response)
      if (response.status === 201) {
        toast.success('Files uploaded successfully');
        console.log(response.data)
      } else {
        toast.error('File upload failed');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }finally{
      setIsSubmitting(false)
    }
  }
  const LoadingSpinner = () => (
    <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
);

  return (
    <>
    <Nav/>
    <Toaster />
    <div className="contact-container">
 
    <div className="left-column">
      <div className="contact-info">
        <h1 className="contact-header">Contact Information</h1>
        <p className="contact-p">
          For inquiries, please use the form below. We're here to assist with
          any questions about our services. Feel free to reach out for more
          details!
        </p>
        <div className="contact-img-container">
          <img className="contact-image" src={Grif} alt=""/>
        </div>
      </div>
    </div>
    <div className="right-column">
      <div className="contact-form-container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="contact-form-group">
            <label htmlFor="name" className="contact-form-group-label">Name:</label>
            <input
              className="contact-form-group-input"
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              value={formData.name}
              required
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="email" className="contact-form-group-label">Email:</label>
            <input
              className="contact-form-group-input"
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              value={formData.email}
              required
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="phone" className="contact-form-group-label">Phone Number:</label>
            <input
              className="contact-form-group-input"
              type="tel"
              id="phone"
              name="phone"
              onChange={handleChange}
              value={formData.phone}
              required
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="message" className="contact-form-group-label">Message:</label>
            <textarea
              className="contact-form-group-textarea"
              id="message"
              name="message"
              rows="4"
              onChange={handleChange}
              value={formData.message}
              required
            ></textarea>
          </div>
          <button id="green-btn" type="submit">
          {isSubmitting ? (
                    <span>Submitting... <LoadingSpinner /></span> // Show loading animation
                ) : (
                    'Send'
                )}</button>
        </form>
      </div>
    </div>
 
  </div>
  <Footer/>
  </>


  );
};

export default Contact;
